import * as React from 'react';
import PropTypes from 'prop-types';

import InventoryScreen from '../../images/odoo-inventory';

const Inventory = ({ bgcolor }) => (
  <div className={bgcolor}>
    <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
      <div>
        <h2 className="text-base font-semibold leading-6 tracking-wide text-indigo-600 uppercase">
          Always know where your stuff is
        </h2>
        <p className="mt-2 text-3xl font-extrabold leading-9 text-gray-900">
          Inventory
        </p>
        <p className="my-4 text-lg leading-7 text-gray-500">
          Modern online warehouse management software.
        </p>
        <InventoryScreen />
      </div>
      <div className="mt-12 lg:mt-0 lg:col-span-2">
        <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-2 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
          <div className="flex space-x-3">
            {/* Heroicon name: check */}
            <svg
              className="flex-shrink-0 w-6 h-6 text-green-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="space-y-2">
              <dt className="text-lg font-medium leading-6 text-gray-900">
                Total traceability
              </dt>
              <dd className="flex space-x-3 lg:py-0 lg:pb-4">
                <span className="text-base leading-6 text-gray-500">
                  Track every stock move from purchase to warehouse bin to sales
                  order. Trace lots or serials upstream or downstream from
                  anywhere in your supply chain.
                </span>
              </dd>
            </div>
          </div>
          <div className="flex space-x-3">
            {/* Heroicon name: check */}
            <svg
              className="flex-shrink-0 w-6 h-6 text-green-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="space-y-2">
              <dt className="text-lg font-medium leading-6 text-gray-900">
                Less stock, no stockouts
              </dt>
              <dd className="flex space-x-3">
                <span className="text-base leading-6 text-gray-500">
                  Fully automated replenishment &mdash; use order points and
                  automated RFQs to make your supply chain more efficient than
                  ever.
                </span>
              </dd>
            </div>
          </div>
          <div className="flex space-x-3">
            {/* Heroicon name: check */}
            <svg
              className="flex-shrink-0 w-6 h-6 text-green-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="space-y-2">
              <dt className="text-lg font-medium leading-6 text-gray-900">
                Improved performance & process time
              </dt>
              <dd className="flex space-x-3">
                <span className="text-base leading-6 text-gray-500">
                  Odoo&apos;s double-entry inventory has no stock input, output
                  or transformation. Instead, all operations are stock moves
                  between locations.
                </span>
              </dd>
            </div>
          </div>
          <div className="flex space-x-3">
            {/* Heroicon name: check */}
            <svg
              className="flex-shrink-0 w-6 h-6 text-green-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="space-y-2">
              <dt className="text-lg font-medium leading-6 text-gray-900">
                Clear and complete reporting
              </dt>
              <dd className="flex space-x-3">
                <span className="text-base leading-6 text-gray-500">
                  Make smarter decisions with real-time dynamic reports that you
                  can save and share with anybody. Keep key information at your
                  fingertips with custom dashboards.
                </span>
              </dd>
            </div>
          </div>
        </dl>
      </div>
    </div>
  </div>
);

Inventory.propTypes = {
  bgcolor: PropTypes.string,
};

Inventory.defaultProps = {
  bgcolor: 'bg-white',
};

export default Inventory;
