import * as React from 'react';
import PropTypes from 'prop-types';

import AccountingScreen from '../../images/odoo-accounting';

const Accounting = ({ bgcolor }) => (
  <div className={bgcolor}>
    <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
      <div>
        <h2 className="text-base font-semibold leading-6 tracking-wide text-indigo-600 uppercase">
          Keep tabs on your money
        </h2>
        <p className="mt-2 text-3xl font-extrabold leading-9 text-gray-900">
          Accounting
        </p>
        <p className="my-4 text-lg leading-7 text-gray-500">
          All the time-saving tools you need to grow your business.
        </p>
        <AccountingScreen />
      </div>
      <div className="mt-12 lg:mt-0 lg:col-span-2">
        <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-3 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
          <div className="flex space-x-3">
            {/* Heroicon name: check */}
            <svg
              className="flex-shrink-0 w-6 h-6 text-green-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="space-y-2">
              <dt className="text-lg font-medium leading-6 text-gray-900">
                Bank Synchronization
              </dt>
              <dd className="flex space-x-3 lg:py-0 lg:pb-4">
                <span className="text-base leading-6 text-gray-500">
                  Get your bank statements synced with your bank automatically,
                  or import files.
                </span>
              </dd>
            </div>
          </div>
          <div className="flex space-x-3">
            {/* Heroicon name: check */}
            <svg
              className="flex-shrink-0 w-6 h-6 text-green-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="space-y-2">
              <dt className="text-lg font-medium leading-6 text-gray-900">
                Invoicing
              </dt>
              <dd className="flex space-x-3">
                <span className="text-base leading-6 text-gray-500">
                  Create sharp and professional invoices, manage recurring
                  billings, and easily track payments.
                </span>
              </dd>
            </div>
          </div>
          <div className="flex space-x-3">
            {/* Heroicon name: check */}
            <svg
              className="flex-shrink-0 w-6 h-6 text-green-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="space-y-2">
              <dt className="text-lg font-medium leading-6 text-gray-900">
                Manage Bills & Expenses
              </dt>
              <dd className="flex space-x-3">
                <span className="text-base leading-6 text-gray-500">
                  Control supplier invoices and get a clear forecast of your
                  future bills to pay.
                </span>
              </dd>
            </div>
          </div>
          <div className="flex space-x-3">
            {/* Heroicon name: check */}
            <svg
              className="flex-shrink-0 w-6 h-6 text-green-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="space-y-2">
              <dt className="text-lg font-medium leading-6 text-gray-900">
                Easy Reconciliation
              </dt>
              <dd className="flex space-x-3">
                <span className="text-base leading-6 text-gray-500">
                  Save time and automate 95% of reconciliation with our smart
                  reconciliation tool.
                </span>
              </dd>
            </div>
          </div>
          <div className="flex space-x-3">
            {/* Heroicon name: check */}
            <svg
              className="flex-shrink-0 w-6 h-6 text-green-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="space-y-2">
              <dt className="text-lg font-medium leading-6 text-gray-900">
                Get paid faster
              </dt>
              <dd className="flex space-x-3">
                <span className="text-base leading-6 text-gray-500">
                  Electronic invoicing and automated follow-ups.
                </span>
              </dd>
            </div>
          </div>
          <div className="flex space-x-3">
            {/* Heroicon name: check */}
            <svg
              className="flex-shrink-0 w-6 h-6 text-green-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="space-y-2">
              <dt className="text-lg font-medium leading-6 text-gray-900">
                Clear dynamic statements
              </dt>
              <dd className="flex space-x-3 lg:border-t-0 lg:py-0 lg:pb-4">
                <span className="text-base leading-6 text-gray-500">
                  Easily create your earnings report, balance sheet or cash flow
                  statements. Quickly filter, zoom, annotate and compare any
                  data.
                </span>
              </dd>
            </div>
          </div>
        </dl>
      </div>
    </div>
  </div>
);

Accounting.propTypes = {
  bgcolor: PropTypes.string,
};

Accounting.defaultProps = {
  bgcolor: 'bg-white',
};

export default Accounting;
