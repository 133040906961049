import * as React from 'react';

import Layout from '../../components/layout';

import CRM from './crm';
import Sales from './sales';
import Inventory from './inventory';
import Accounting from './accounting';
import POS from './pos';

export default () => (
  <Layout>
    <div className="bg-white">
      <div className="pt-12 sm:pt-16 lg:pt-20">
        <div className="max-w-screen-xl px-4 pb-8 mx-auto sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold leading-9 text-gray-900 sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none">
              About Odoo ERP
            </h2>
            <p className="max-w-2xl mx-auto mt-3 text-xl leading-7 text-gray-500 sm:mt-4">
              The Odoo family of apps work seamlessly together &mdash; giving
              you the ability to automate and track{' '}
              <span className="text-teal-700">
                <strong>everything you do</strong>
              </span>{' '}
              &mdash; centralized, online, accessible anywhere on any device.
            </p>
          </div>
        </div>
        <CRM bgcolor="bg-white" />
        <Sales bgcolor="bg-gray-50" />
        <Inventory bgcolor="bg-white" />
        <Accounting bgcolor="bg-gray-50" />
        <POS bgcolor="bg-white" />
      </div>
    </div>
  </Layout>
);
